import { request } from '@/request/request';
import { parameterChange } from '@/utils/utils';

export default {
  // 获取正式比赛试题
  getObjInfo(data) {
    return request({
      url: "/obj-info/getObjInfo",
      method: "get",
      data,
    });
  },
  // 获取用户测试试题
  getObjTestInfo(data) {
    return request({
      url: "/obj-info/getObjTestInfo",
      method: "get",
      data,
    });
  },
  // 评判学生考试成绩
  submitObjAnswer(data) {
    return request({
      url: "/obj-answer/submitObjAnswer",
      method: "post",
      data,
    });
  },
  // 编译题交卷
  raceSubmit(data) {
    return request({
      url: "/score-record/raceSubmit",
      method: "post",
      data,
    });
  },
  // 提交代码
  submitCompileCode(data) {
    return request({
      url: "/compile-answer/submitCompileCode",
      method: "post",
      data,
    });
  },
  // 测试比赛提交代码
  submitCompileTestCode(data) {
    return request({
      url: "/compile-answer/submitCompileTestCode",
      method: "post",
      data,
    });
  },

  // 运行代码
  runCompileCode(data) {
    return request({
      url: "/compile-answer/runCompileCode",
      method: "post",
      data,
    });
  },
  // 测试试卷编译题
  getCompileTestInfo(data) {
    return request({
      url: "/compile-info/getCompileTestInfo",
      method: "get",
      data,
    });
  },
  // 正式试卷编译题
  getCompileInfo(data) {
    return request({
      url: "/compile-info/getCompileInfo",
      method: "get",
      data,
    });
  },
  // 获取当前学生的比赛状态
  getPaperInfo(data) {
    return request({
      url: "/stu-info/getPaperInfo",
      method: "get",
      data,
    });
  },
  // 保存编译题代码
  saveCompileCode(data) {
    return request({
      url: "/compile-answer/saveCompileCode",
      method: "post",
      data,
    });
  },
  // 获取编译题代码
  getCompileCode(data) {
    return request({
      url: "/compile-answer/getCompileCode",
      method: "post",
      data,
    });
  },
  // 赛前测试交卷
  testSubmit(data) {
    return request({
      url: "/score-record/testSubmit",
      method: "post",
      data,
    });
  },
  // 更新比赛结束自动提交状态
  updateAutoSubmit(data) {
    return request({
      url: "/stu-info/updateAutoSubmit",
      method: "post",
      data,
    });
  },
};
