import { defineStore } from "pinia";
import * as api from "@/api/index";

const useChatStore = defineStore("chat", {
  state: () => ({
    //学生id
    stuId: 0,
    // 是否查看图片
    isLook: false,
    // 当前图片
    nowImg: "",
    // 当前图片索引
    nowImgIndex: 0,
    // 当前图片列表描述
    nowImgDes: "",
    // 生成图片缩略图
    imgsList: [],
    // 查看图片详情
    imgbList: [],
    //剩余提交次数
    subNum: 0,
    //ai允许最大生成次数
    subTotalNum: 10,
    //ai允许生成次数
    subAiNum: 10,
    //提交弹框类型
    modalType: "auto",
    //是否是自动交卷
    isAutoSub: false,
    // 参赛主题
    theme: "自然与科技和和谐共存",
    // 当前会话的回答记录
    chatData: [],
    // 所有会话的回答记录
    chatRecord: [],
    // chatRecord: [
    //   {
    //     title: "自然与科技和和谐共存",
    //     des: "自然与科技和谐共存,皮克斯风格,低聚,轮廓光,Q 版",
    //     chatData: [
    //       {
    //         type: "user",
    //         username: "aa",
    //         content: "自然与科技和谐共存,皮克斯风格,低聚,轮廓光,Q 版",
    //         img: "https://cdn.codedancing.cn/cover/a0371202407151512436797.png",
    //         taskId: "21312312",
    //       },
    //       {
    //         type: "ai",
    //         avatar:
    //           "https://cdn.codedancing.cn/cover/7a86b202410121531194621.png",
    //         imgs: [
    //           "https://cdn.codedancing.cn/cover/7a86b202410121531194621.png",
    //           "https://cdn.codedancing.cn/cover/b632b202402221534408030.png",
    //           "https://cdn.codedancing.cn/cover/a0371202407151512436797.png",
    //           "https://cdn.codedancing.cn/cover/c4182202402221527529986.png",
    //         ],
    //         state: "success",
    //         taskId: "1273123123123",
    //       },
    //     ],
    //   },
    //   {
    //     title: "自然与科技和和谐共存",
    //     des: "",
    //     chatData: [],
    //   },
    // ],
    // 当前会话的索引
    nowIndex: 0,
    // 是否已经提交作品
    subState: false,
  }),

  getters: {},
  actions: {
    set_stuId(stuId) {
      this.stuId = stuId;
    },
    set_isLook(isLook) {
      this.isLook = isLook;
    },
    set_nowImg(nowImg) {
      this.nowImg = nowImg;
    },
    set_nowImgIndex(nowImgIndex) {
      this.nowImgIndex = nowImgIndex;
    },
    set_nowImgDes(nowImgDes) {
      this.nowImgDes = nowImgDes;
    },

    set_imgsList(imgsList) {
      this.imgsList = imgsList;
    },
    set_imgbList(imgbList) {
      this.imgbList = imgbList;
    },
    set_subNum(subNum) {
      this.subNum = subNum;
    },
    set_subTotalNum(subTotalNum) {
      this.subTotalNum = subTotalNum;
    },
    set_subAiNum(subAiNum) {
      this.subAiNum = subAiNum;
    },
    set_modalType(modalType) {
      this.modalType = modalType;
    },
    set_isAutoSub(isAutoSub) {
      this.isAutoSub = isAutoSub;
    },
    set_theme(theme) {
      this.theme = theme;
    },
    set_chatData(chatData) {
      this.chatData = chatData;
    },
    set_chatRecord(chatRecord) {
      this.chatRecord = chatRecord;
    },
    set_nowIndex(nowIndex) {
      this.nowIndex = nowIndex;
    },
    set_subState(subState) {
      this.subState = subState;
    },
  },
  persist: {
    afterRestore: () => {
      console.log("set success");
    },
    key: "chat",
    paths: ["chatRecord", "nowIndex", "stuId"],
  },
});

export default useChatStore;
