<template>
  <a-drawer
    :placement="placement"
    :visible="open"
    @close="onClose"
    :closable="false"
    :height="900"
  >
    <div class="main">
      <div class="title">开发笔记</div>
      <div class="contents">
        <div class="left">团队概况</div>
        <a-textarea
          :rows="3"
          v-model:value="noteList.teamProfile"
          placeholder="详细介绍参赛队员和指导教师的相关信息。"
        ></a-textarea>
      </div>
      <div class="contents">
        <div class="left">选题背景</div>
        <a-textarea
          v-model:value="noteList.background"
          :rows="4"
          placeholder="阐述选择该主题的原因、背景情况，以及设计目标和制作计划。"
        ></a-textarea>
      </div>
      <div class="contents">
        <div class="left">制作过程详述</div>
        <a-textarea
          v-model:value="noteList.processDetail"
          :rows="4"
          placeholder="描述作品所使用的制作工具和技术;解释素材的搜集、建模、生成等步骤;阐述AIGC数字艺术创意设计作品背后的设计理念和所要传达的思想。同时，可以分享设计过程中的灵感来源、遇到的挑战及其解决方案。可以通过图文、列表等形式进行详细说明。"
        ></a-textarea>
      </div>
      <div class="contents">
        <div class="left">创新亮点</div>
        <a-textarea
          v-model:value="noteList.innovationHighlight"
          :rows="4"
          placeholder="介绍作品中具有标志性的场景。"
        ></a-textarea>
      </div>
      <div class="contents">
        <div class="left">作品亮点</div>
        <a-textarea
          v-model:value="noteList.worksHighlight"
          :rows="4"
          placeholder="阐明作品中新颖、创造性或独特之处。"
        ></a-textarea>
      </div>
      <div class="contents">
        <div class="left">心得体会</div>
        <a-textarea
          v-model:value="noteList.experience"
          :rows="4"
          placeholder="分享在设计和制作参赛作品过程中的体验和收获，以及对未来的展望。"
        ></a-textarea>
      </div>
      <div class="footer">
        <div class="btn sub" @click="onSubmit">确认保存</div>
        <div class="btn no" @click="onClose">暂不保存</div>
      </div>
      <img
        class="close_icon"
        src="@/assets/chat/close.svg"
        alt=""
        @click="onClose"
      />
    </div>
  </a-drawer>
</template>
<script setup>
import { message } from "ant-design-vue";
import { ref, getCurrentInstance, onMounted } from "vue";
const { proxy } = getCurrentInstance();
const placement = ref("bottom");
const open = ref(false);
const noteList = ref({
  teamProfile: "",
  background: "",
  processDetail: "",
  innovationHighlight: "",
  worksHighlight: "",
  experience: "",
});
onMounted(() => {
  getDevelopmentNote();
});
const getDevelopmentNote = () => {
  proxy.$api.getDevelopmentNote().then((res) => {
    if (res.data) {
      const {
        teamProfile,
        background,
        processDetail,
        innovationHighlight,
        worksHighlight,
        experience,
      } = res.data;

      noteList.value = {
        teamProfile,
        background,
        processDetail,
        innovationHighlight,
        worksHighlight,
        experience,
      };
    }
  });
};

const updateDevelopmentNote = () => {
  proxy.$api.updateDevelopmentNote(noteList.value).then((res) => {
    if (res.resultCode === 200) {
      proxy.$message.info("提交成功");
    }
  });
};
const showDrawer = () => {
  open.value = true;
};
const onClose = () => {
  open.value = false;
  getDevelopmentNote();

};
const onSubmit = () => {
  updateDevelopmentNote();
  onClose();
};
defineExpose({ showDrawer });
</script>
<style lang="less" scoped>
.main {
  width: 800px;
  height: fit-content;
  margin: 0 auto 50px auto;
  background: #ffffff;
  font-family: PingFang SC, PingFang SC;

  .title {
    border-bottom: 1px solid #e7e7e7;
    height: 64px;
    font-weight: 600;
    font-size: 24px;
    color: #3d3d3d;
    line-height: 64px;
    text-align: center;
    margin-bottom: 30px;
  }
  .contents {
    display: flex;
    align-items: top;
    justify-content: flex-end;
    margin: 16px 0;
    .left {
      text-align: right;
      margin-right: 16px;
      white-space: nowrap;
    }
    textarea.ant-input {
      width: 700px !important;
      border-radius: 3px 3px 3px 3px;
      border: 1px solid #dcdcdc;
    }
  }
  .footer {
    display: flex;
    margin-top: 20px;

    .btn {
      width: 184px;
      height: 42px;
      font-family: SF Pro, SF Pro;
      border-radius: 3px;
      font-weight: 400;
      font-size: 14px;
      line-height: 42px;
      text-align: center;
      cursor: pointer;
    }
    .sub {
      background: #366ef4;
      color: rgba(255, 255, 255, 0.9);
      margin-right: 16px;
      margin-left: 100px;
    }
    .no {
      background: #e7e7e7;
      color: rgba(0, 0, 0, 0.9);
    }
  }
  .close_icon {
    position: absolute;
    right: 10px;
    top: 10px;
    width: 40px;
    height: 40px;
    cursor: pointer;
  }
}
:deep(.ant-drawer-body) {
  padding: 0;
}

.ant-drawer-content {
  overflow: visible !important;
}
</style>
