import { request } from "@/request/request";
import axios from "axios";
import { parameterChange } from "@/utils/utils";

const requests = axios.create({
  baseURL: "http://localhost:3000/api",
  timeout: 60000,
  headers: {},
});
// mork数据

export default {
  record(data) {
    return requests({
      url: "/record",
      method: "get",
      data,
    });
  },
  // 获取题目
  getAigcInfo(data) {
    return request({
      url: "/aigc-info/getAigcInfo",
      method: "get",
      data,
    });
  },
  // 获取开发笔记
  getDevelopmentNote(data) {
    return request({
      url: "/aigc-answer/getDevelopmentNote",
      method: "get",
      data,
    });
  },
  // 填写开发笔记
  updateDevelopmentNote(data) {
    return request({
      url: "/aigc-answer/updateDevelopmentNote",
      method: "post",
      data,
    });
  },
  // // 网络图片转base64
  // imageToBase64(data) {
  //   return request({
  //     url: "/file/imageToBase64?" + parameterChange(data),
  //     method: "get",
  //     data,
  //   });
  // },
  // 上传网络图片
  uploadWebImage(data) {
    return request({
      url: "/file/uploadWebImage",
      method: "post",
      data,
    });
  },
  // 上传本地图片
  uploadLocalImage(data) {
    return request({
      url: "/file/uploadLocalImage",
      method: "post",
      data,
    });
  },
  // 获取作品
  aigcGetAnswer(data) {
    return request({
      url: "/aigc-answer/getAnswer",
      method: "get",
      data,
    });
  },
  // 提交作品
  aigcSubmitAnswer(data) {
    return request({
      url: "/aigc-answer/submitAnswer",
      method: "post",
      data,
    });
  },
  // 提交生成图片内容
  submitQuestion(data) {
    return request({
      url: "/v1/services/aigc/text2image/image-synthesis",
      method: "post",
      data,
    });
  },
  watchSubmitQuestion(taskId,data={}) {
    return request({
      url: `/v1/tasks/${taskId}`,
      method: "get",
      data,
    });
  },
};


