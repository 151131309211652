/*
 * @Date: 2022-07-11 14:27:16
 * @LastEditTime: 2024-05-30 18:10:51
 */
import { useUserStore, useCommonStore } from "@/store/index";
import { message } from "ant-design-vue";
// import { md5 } from 'md5js';
import dayjs from "dayjs";
import * as api from "@/api";

export function getCopyRight() {
  const { hostname } = window.location,
    subdomain = hostname.match(/[^.]+\.[^.]+$/)?.[0];

  let obj = {};
  // 根据子域名的部分值匹配并返回备案信息
  switch (subdomain) {
    case "scidsai.cn":
      obj = {
        copyRight: `Copyright ©2018-${new Date().getFullYear()} 广州华南数字科学与人工智能研究院`,
        icp: "粤ICP备2024195059号",
      };
      break;
    case "codedancing.cn":
      obj = {
        copyRight: `Copyright ©2018-${new Date().getFullYear()} 广东学思科技有限公司`,
        icp: "粤ICP备2023016999号",
      };
      break;
    default:
      obj = {
        copyRight: `Copyright ©2018-${new Date().getFullYear()} 测试版权信息`,
        icp: "粤ICP备1111111111号",
      };
      break;
  }

  // 跳转到备案网站
  const go2integrated = () => {
    const link = document.createElement("a"),
      url = "https://beian.miit.gov.cn/#/Integrated/index";

    link.href = url;
    link.target = "blank";
    link.click();
  };
  return { info: obj, go2integrated: go2integrated };
}

/**
 * @description: 内容拼接
 * @param {*} data
 * @return {*}
 */
export function parameterChange(data) {
  let res = ``;
  for (const key in data) {
    let dataKey = "";
    if (data[key] || data[key] === 0) dataKey = data[key];
    res = `${res}&${key}=${dataKey}`;
  }
  return res.substr(1);
}

/**
 * @description: 定时拍照
 * @param {*} executeImmediately 是否立刻执行
 * @return {*}
 */
export function screenshotRandomly(executeImmediately = false) {
  let counter = 1;
  const commonStore = useCommonStore();
  const { mediaRef, shotInterval } = commonStore;
  const randomTime = getRandomInterval();

  // 随机生成一个【1<random<4】或者【4<random<6】的时间
  function getRandomInterval() {
    const flag = Math.random() > 0.5,
      minMin = flag ? 4 * 60 * 1000 : 1 * 60 * 1000,
      intervalMin = 2 * 60 * 1000,
      random = Math.floor(Math.random() * intervalMin) + minMin; // 生成1到5分钟之间的随机数
    return random;
  }

  function do5() {
    const interval = setInterval(() => {
      if (counter > 5) {
        counter = 1;
        clearInterval(interval);
        return;
      }

      // 调用getUserMedia组件的拍摄方法
      mediaRef.screenshot();
      counter++;
    }, randomTime);
  }

  executeImmediately && do5();

  // 每30分钟拍5张照片
  const intervalId = setInterval(() => {
    do5();
  }, 30 * 60 * 1000);
  intervalId && commonStore.SET_SHOT_INTERVAL(intervalId);
}

/**
 * @description: 数字补0
 * @param {*} 数字
 * @return {*} 数字
 */
export function formatNumber(num) {
  num = num.toString();
  return num[1] ? num : `0${num}`;
}

/**
 * @description: 比赛信息
 * @returns isShow：是否展示操作流程；temp：比赛信息
 */
export function getPreMatchInfo() {
  return new Promise((resolve) => {
    api.default.getPreMatchInfo().then((res) => {
      if (res) {
        const guideImgMap = {
          python: require("@/assets/home/gPython.png"),
          scratch: require("@/assets/home/gScratch.png"),
          "c++": require("@/assets/home/gC++.png"),
        };

        const temp = res.data;
        const {
          testStart,
          testEnd,
          raceStart,
          eventName,
          groupName,
          langName,
          teamArea,
          raceZone,
          racePeriod,
          raceType,
          raceEnd,
          cameraState,
        } = temp;
        const comMess = {
          eventName,
          groupName,
          langName,
          teamArea,
          raceZone,
          racePeriod,
          raceType,
          cameraState,
        };

        temp.testStartTimeF = dayjs(testStart).format("M月D日 HH:mm");
        temp.testEndTimeF = dayjs(testEnd).format("M月D日 HH:mm");
        temp.raceTimeF = dayjs(raceStart).format("YYYY年M月D日 HH:mm");
        temp.raceTimeE = dayjs(raceEnd).format("M月D日 HH:mm");
        temp.raceTimeF2 = dayjs(raceStart).format("M月D日 HH:mm");
        temp.isScratch = langName.indexOf("scratch") !== -1;

        const isShow = Object.keys(guideImgMap).includes(
            langName.toLowerCase()
          ),
          commonStore = useCommonStore();

        commonStore.SET_COMMESS(comMess);
        sessionStorage.setItem("comMess", JSON.stringify(comMess));

        resolve({ isShow, temp });
      }
    });
  });
}

/**
 * @description: 深拷贝
 * @param {object|array} data - 对象或数组
 * @return {string} 结果
 */
export function deepClone(obj, hash = new WeakMap()) {
  // 基础类型或 null/undefined，直接返回
  if (obj == null || typeof obj !== "object" || typeof obj === "function") {
    return obj;
  }
  // 处理 Date 类型
  if (obj instanceof Date) {
    return new Date(obj);
  }
  // 处理 RegExp 类型
  if (obj instanceof RegExp) {
    return new RegExp(obj);
  }
  // 处理 Map 类型
  if (obj instanceof Map) {
    const mapClone = new Map();
    obj.forEach((value, key) => {
      mapClone.set(key, deepClone(value, hash));
    });
    return mapClone;
  }
  // 处理 Set 类型
  if (obj instanceof Set) {
    const setClone = new Set();
    obj.forEach((value) => {
      setClone.add(deepClone(value, hash));
    });
    return setClone;
  }
  // 处理 ArrayBuffer 类型
  if (obj instanceof ArrayBuffer) {
    return obj.slice(0);
  }
  // 避免循环引用
  if (hash.has(obj)) {
    return hash.get(obj);
  }
  // 创建新的对象或数组
  const result = Array.isArray(obj) ? [] : {};
  hash.set(obj, result);
  // 遍历对象的属性或数组的元素进行递归拷贝
  for (let key in obj) {
    // 使用 hasOwnProperty 来确保只处理自身的属性
    if (obj.hasOwnProperty(key)) {
      result[key] = deepClone(obj[key], hash); // 递归拷贝
    }
  }
  return result;
}
