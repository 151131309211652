<!--
 * @Author: gyh
 * @Date: 2024-05-23 16:59:20
 * @LastEditTime: 2024-05-30 16:04:51
-->
<template>
  <!-- <p class="title">{{ config.competition[VUE_APP_GAME] }}</p>
  <p class="title">{{ comMess.eventName }}</p> -->
  <div class="message_box">
    <p class="sub-title">
      <!-- 青创客展示组别、编程语言、赛区/上一级比赛赛区、赛期 -->
      <template v-if="VUE_APP_GAME == 'qck'">
        <!-- {{ comMess.groupName }} {{ comMess.langName }}
        {{ comMess.raceZonePast ?? comMess.raceZone }}
        {{ commonStore.periodMap[comMess.racePeriod] }} -->
        <div>
          <i>{{ comMess.groupName }} </i><i>{{ comMess.langName }} </i
          ><i>{{ comMess.raceZonePast ?? comMess.raceZone }} </i>
          <i v-if="commonStore.RaceType === 0">
            {{ commonStore.periodMap[commonStore.comMess?.racePeriod] }}</i
          ><i v-else-if="[1, 2, 3].includes(commonStore.RaceType)">
            {{ raceTypeMap[commonStore.RaceType] }}
          </i>
        </div>
        <div class="tip">
          <template v-if="path === 'upload'">
            <img class="tip_img" src="@/assets/chat/tip.svg" alt="" />
            <div class="tip_text">
              本次比赛还能提交 <span>{{ chatStore.subNum }}</span> 次
            </div>
          </template>
          <template v-if="path === 'create'">
            <img class="tip_img" src="@/assets/chat/tip.svg" alt="" />
            <div class="tip_text">
              请在<span>生成并提交最终作品</span>及<span>填写提交开发笔记</span>后，进行交卷！
              <button class="tip_text_btn" @click="subPaper">交卷</button>
            </div>
          </template>
        </div>
      </template>
      <!-- 花都展示组别、编程语言 -->
      <template v-else-if="VUE_APP_GAME == 'hdycp'"
        >{{ comMess.groupName }} {{ comMess.langName }}</template
      >
      <!-- 山东展示编程语言 -->
      <template v-else>{{ comMess.langName }}</template>
    </p>
    <slot name="right"></slot>
  </div>
</template>

<script setup>
import { config } from "@/config";
import { ref } from "vue";
import { useCommonStore, useChatStore } from "@/store";

import { useRoute, useRouter } from "vue-router";
const route = useRoute();
const router = useRouter();
const path = ref(route.name);
const raceTypeMap = { 1: "预选赛", 2: "市赛", 3: "省赛" };
const chatStore = useChatStore();

const commonStore = useCommonStore(),
  { VUE_APP_GAME } = process.env,
  props = defineProps({ comMess: Object }); // 比赛信息

const subPaper = () => {
  chatStore.set_modalType('subPaper')
};

</script>

<style lang="less" scoped>
.message_box {
  padding: 12px 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 56px;
  background: #f9f6d0;
}
// .title {
//   height: 36px;
//   font-size: 28px;
//   font-weight: 600;
//   color: rgba(0, 0, 0, 0.9);
//   line-height: 36px;
//   text-align: center;

//   &:first-of-type {
//     margin-top: 48px;
//   }

//   &:nth-of-type(2) {
//     color: rgba(0, 0, 0, 0.6);
//     margin-top: 24px;
//   }
// }
.sub-title {
  height: 100%;
  flex: 1;
  font-family: SF Pro, SF Pro;
  font-weight: 590;
  font-size: 20px;
  color: #8b572a;
  line-height: 32px;
  text-align: left;
  font-style: normal;
  text-transform: none;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  .tip {
    display: flex;
    align-items: center;
    margin-left: auto;
    font-size: 16px;
    font-weight: 400;

    .tip_img {
      width: 28px;
      height: 28px;
    }
    .tip_text {
      color: rgba(0, 0, 0, 0.9);
      line-height: 28px;
      margin: 0 16px 0 8px;
      font-family: SF Pro, SF Pro;
      span {
        color: #d54941;
      }
      .tip_text_btn {
        display: inline-block;
        padding: 8px 54px;
        background: #f6685d;
        border-radius: 20px 20px 20px 20px;
        font-weight: 400;
        font-size: 16px;
        color: #ffffff;
        line-height: 24px;
        border: none;
        cursor: pointer;
      }
    }
    .tip_btn {
      padding: 0 24px;
      height: 40px;
      background: #e3f9e9;
      border-radius: 20px;
      border: 1px solid #2ba471;
      font-family: PingFang SC, PingFang SC;
      color: #008858;
      line-height: 38px;
      text-align: center;
      box-sizing: border-box;
      cursor: pointer;
    }
  }
}
i {
  font-style: normal;
  margin-left: 5px;
}
</style>
